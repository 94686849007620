<template>
<div class="d-flex">
   
    <div  :key="c.code" v-for="(c, k) in codes" class="mb-2 mr-2">
        <b-card class="w-100">
            <h6 class="text-center">Voor {{c.type == 'member' ? userData.displayName : `ouders van `}}</h6>
    <pdf-generator  :data="{ver: c, user: userData}" :type="c.type" :ref="`pdfGenerator`" :hidden="false">
          <b-button
            slot="trigger" @click="$refs.pdfGenerator[k].generatePDF()"
                class="mt-1 w-100"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="loading"
              >
              Download flyer
          </b-button>
          </pdf-generator>
    </b-card>
    </div>
</div>
  
</template>

<script>
import { functions } from "@/firebase/firebaseConfig";
import {
  BButton,
  BCard,
  BCol,
  BRow
} from "bootstrap-vue";
import PdfGenerator from '@/components/PdfGenerator.vue';
import Ripple from "vue-ripple-directive";
export default {
data(){
    return {
        codes: [],
        loading: false,
    }
},
directives: {
    Ripple,
  },
props: {
    userData: {
      type: Object,
      required: true,
    },
  },
components: {
    PdfGenerator,
    BButton,
    BCard,
    BCol,
    BRow
},
created(){
    this.getVerificationCode();
  },
methods: {
    onClick(c){
        console.log(this.$refs);
    },
    async getVerificationCode(){
      var res = await functions.httpsCallable('getCodeByUoid')({organisationId: this.$store.state.organisation.id, uoid: this.userData.uoid});
      if(res.data){
        this.codes = res.data;
      }
      
    },
}
}
</script>

<style>

</style>