<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="updateUser">
        <b-row>
          <!-- Field: Firstname -->
          <b-col cols="12" md="4">
            <b-form-group label="Voornaam" label-for="firstname">
              <b-form-input
                id="firstname"
                :class="$v.user.firstname.$error ? 'is-invalid' : ''"
                v-model="user.firstname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Naam" label-for="name">
              <b-form-input
                id="name"
                :class="$v.user.lastname.$error ? 'is-invalid' : ''"
                v-model="user.lastname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: DisplayName -->
          <b-col cols="12" md="4">
            <b-form-group label="Weergavenaam" label-for="displayName">
              <b-form-input
                id="displayName"
                :class="$v.user.displayName.$error ? 'is-invalid' : ''"
                v-model="user.displayName"
                type="text"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Birth Date -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Geboortedatum" label-for="birth-date">
              <cleave
                id="birthday"
                class="form-control"
                :raw="false"
                :options="cleaveBirth"
                :class="$v.user.birthday.$error ? 'is-invalid' : ''"
                :disabled="loading"
                v-model="user.birthday"
                placeholder="dd/mm/jjjj"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gender -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group
              label="Geslacht"
              label-for="gender"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="gender"
                v-model="user.gender"
                :class="$v.user.gender.$error ? 'is-invalid' : ''"
                :options="genderOptions"
                value="male"
              />
            </b-form-group>
          </b-col>
          <!-- Field: Type -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Type" label-for="type" label-class="mb-1">
              <v-select
                v-model="user.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userRoles"
                :class="$v.user.type.$error ? 'is-invalid' : ''"
                :getOptionLabel="(option) => getRoleSingular(option)"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h6 class="section-label mb-1 mt-2">
              Persoonlijke Contactgegevens
            </h6>
          </b-col>

          <!-- phone -->
          <b-col md="8" class="mb-2">
            <b-row :key="k" v-for="(p, k) in user.phones">
              <b-col cols="10">
                <b-form-group
                  label-for="phone"
                  :label="'Telefoonnummer ' + (k + 1)"
                >
                  <cleave
                    class="form-control"
                    :options="cleavePhone"
                    :disabled="loading"
                    v-model="user.phones[k]"
                    :class="
                      $v.user.phones.$each[k].$error ? 'is-invalid' : ''
                    "
                    placeholder="Telefoonnummer"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removePhone(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addPhone"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Telefoonnummer toevoegen</span>
            </b-button>
          </b-col>
          <!-- phone -->

          <!-- email -->
          <b-col md="8" class="mb-2">
            <b-row :key="k" v-for="(p, k) in user.emails">
              <b-col cols="10">
                <b-form-group label-for="email" :label="'E-mail ' + (k + 1)">
                  <b-form-input
                    class="form-control"
                    v-model="user.emails[k]"
                    :name="'email-' + k"
                    :class="
                      $v.user.emails.$each[k].$error ? 'is-invalid' : ''
                    "
                    :disabled="loading"
                    placeholder="E-mail"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeEmail(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addEmail"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>E-mail toevoegen</span>
            </b-button>
          </b-col>
          <!-- email -->

          <!-- email -->
          <b-col md="8" class="mb-2">
            <b-row :key="k" v-for="(p, k) in user.addresses">
              <b-col sm="12">
                <h6 class="form-label mb-1 mt-2">
                  {{ k == 0 ? "Hoofdadres" : "Adres " + (k + 1) }}
                </h6>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Straat">
                  <b-form-input
                    class="form-control"
                    v-model="user.addresses[k].street"
                    :class="
                      $v.user.addresses.$each[k].street.$error
                        ? 'is-invalid'
                        : ''
                    "
                    :disabled="loading"
                    placeholder="Straat"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Nr.">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="user.addresses[k].housenumber"
                    :class="
                      $v.user.addresses.$each[k].housenumber.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Nr."
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Bus">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="user.addresses[k].addition"
                    :class="
                      $v.user.addresses.$each[k].addition.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Bus"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAddress(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Postcode">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="user.addresses[k].postal"
                    :class="
                      $v.user.addresses.$each[k].postal.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Plaats">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="user.addresses[k].city"
                    :class="
                      $v.user.addresses.$each[k].city.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Plaats"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addAddress"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Adres toevoegen</span>
            </b-button>
          </b-col>

          <b-col sm="12" v-if="customfields.length > 0">
            <h6 class="section-label mb-1 mt-2">Aangepaste velden</h6>
          </b-col>
          <!-- Field: Firstname -->
          <b-col
            cols="12"
            md="4"
            :key="'field-' + key"
            v-for="(field, key) in customfields"
          >
            <b-form-group
              v-if="field.type == 'string'"
              :label="field.name"
              :label-for="field.id"
            >
              <b-form-input
                :id="field.id"
                v-model="user.custom[field.id]"
              />
            </b-form-group>
            <b-form-checkbox
              v-else-if="field.type == 'boolean'"
              v-model="user.custom[field.id]"
              switch
              inline
              class="custom-control-success"
            >
              {{ field.name }}
            </b-form-checkbox>
             <b-form-group
              v-if="field.type == 'number'"
              :label="field.name"
              :label-for="field.id"
            >
              <b-form-input
                :id="field.id"
                type="number"
                v-model="user.custom[field.id]"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-col sm="12" v-if="user.privacy">
          <h6 class="section-label mb-1 mt-2">Privacy</h6>
          <b-row class="pb-1">
          <b-form-checkbox
              v-model="user.privacy.canPhoto"
              @input="handlePrivacyChange"
              switch
              inline
              class="custom-control-success"
            >
              Mag of de foto
            </b-form-checkbox>
            </b-row>
              <b-row class="pb-1">
          <b-form-checkbox
              v-model="user.privacy.sharePhotoClass"
              @input="handlePrivacyChange"
              switch
              inline
              class="custom-control-success"
            >
              Foto's mogen gedeeld worden met familie van klasgenoten via beveileigd platform
            </b-form-checkbox>
            </b-row>
              <b-row class="pb-1">
          <b-form-checkbox
              v-model="user.privacy.sharePhotoSchool"
              @input="handlePrivacyChange"
              switch
              inline
              class="custom-control-success"
            >
              Foto's mogen gedeeld worden met familie van schoolgenoten via beveileigd platform
            </b-form-checkbox>
            </b-row>
              <b-row class="pb-1">
            <b-form-checkbox
              v-model="user.privacy.sharePhotoPublic"
              @input="handlePrivacyChange"
              switch
              inline
              class="custom-control-success"
            >
              Foto's mogen openbaar gedeeld worden (website, sociale media,...)
            </b-form-checkbox>
            </b-row>
        </b-col>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :disabled="loading"
        >
          Opslaan
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          @click="resetData"
          :disabled="loading"
        >
          Annuleren
        </b-button>
      </b-form>
    </div>
  </b-overlay>
</template>

<script>
import Vue from 'vue';
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BOverlay,
  BFormFile,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.be";

import Ripple from "vue-ripple-directive";

import mainMixin from "@/mixins/mainMixin";



export default {
  mixins: [mainMixin],
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    Cleave,
    BOverlay,
    BFormFile
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed:{
    customfields(){
      var res = [];
      if(this.$store.state.organisation && this.$store.state.organisation.customFields){
        this.$store.state.organisation.customFields.forEach(f => {
          if(f.enabled){
            res.push(f);
          }
        })
      }
      return res;
    }
  },
  created(){
    if(!this.user.privacy){
      this.user.privacy = {
        canPhoto: false,
        sharePhotoClass: false,
        sharePhotoSchool: false,
        sharePhotoPublic: false
      }
    }
  },
  data() {
    return {
      loading: false,
      user: this.userData,
      profilePic: this.userData.profilePic
        ? this.userData.profilePic.medium
        : null,
      profileFile: null,

      cleaveBirth: {
        date: true,
        delimiter: "/",
        datePattern: ["d", "m", "Y"],
      },
      cleavePhone: {
        phone: true,
        phoneRegionCode: "BE",
      },
    };
  },
  
  methods: {
    avatarText: avatarText,
    handlePrivacyChange(){
      // TODO
    },
    inputImageRenderer() {
      const file = this.profileFile;
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.profilePic = reader.result;
          this.uploadFile();
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },

    uploadFile() {
      this.loading = true;
      this.$store
        .dispatch("uploadImageToPath", {
          imageName: `/organisations/${
            this.$store.state.organisation.id
          }/users/${this.user.uid || this.user.uoid}/${
            this.user.uid || this.user.uoid
          }.${this.profileFile.name.split(".").pop()}`,
          file: this.profileFile,
          notify: (arg) => this.$toast(arg),
        })
        .then((result) => {
          this.user.profilePic = result;
          if (!this.user.uoid) {
            this.user.uoid = this.$route.params.uoid;
          }
          return this.$store.dispatch("userManagement/updateUser", {
            orgId: this.$store.state.organisation.id,
            user: this.user,
          });
        })
        .then(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Profiel foto aangepast!",
              icon: "CheckICon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error!",
              text: error.message,
              icon: "AlertCirleIcon",
              variant: "danger",
            },
          });
        });
    },
    resetData() {
      this.$v.$reset();
      this.$emit("resetData", null);
    },

    
    updateUser() {
      if (!this.$v.$invalid) {
        this.loading = true;
        if (!this.user.uoid) {
          this.user.uoid = this.$route.params.uoid;
        }
        if (!this.user.organisations[this.$store.state.organisation.id]) {
          this.user.organisations[this.$store.state.organisation.id] = {
            type: this.user.type,
          };
        }
        this.user.organisations[
          this.$store.state.organisation.id
        ].type = this.user.type;
        this.user.email = this.user.emails[0] ?? null;
        this.user.phone = this.user.phones[0] ?? null;
        this.user.address = this.user.addresses[0] ?? null;
        this.$store
          .dispatch("userManagement/updateUser", {
            orgId: this.$store.state.organisation.id,
            user: this.user,
          })
          .then(() => {
            this.$emit("input", this.user);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Wijzigingen opgeslaan!",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$v.$touch();
      }
    },
    addPhone() {
      this.user.phones.push("");
    },
    removePhone(index) {
      this.user.phones.splice(index, 1);
    },
    addEmail() {
      this.user.emails.push("");
    },
    removeEmail(index) {
      this.user.emails.splice(index, 1);
    },
    addAddress() {
      this.user.addresses.push({});
    },
    removeAddress(index) {
      this.user.addresses.splice(index, 1);
    },
  },
  
  validations: {
    user: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      displayName: {
        required,
      },
      birthday: {},
      gender: {},
      type: {
        required,
      },
      emails: {
        $each: {
          required,
          email,
        },
      },
      phones: {
        $each: {
          minLength: minLength(9),
        },
      },
      addresses: {
        $each: {
          street: {
            minLength: minLength(2),
          },
          housenumber: {
            numeric,
          },
          addition: {},
          postal: {
            numeric,
          },
          city: {},
        },
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
