<template>
  <b-overlay :show="loading" rounded="sm">
    <div>
      <div v-if="isNew">
        <!-- Media -->
        <h4 class="mb-1">Zoek een bestaand contact</h4>
        <v-select
          class="mb-1"
          v-model="newContacts"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="displayName"
          placeholder="Zoeken..."
          :options="$store.state.userManagement.contacts"
        />
        <b-button variant="primary" @click="addNewContacts" :disabled="newContacts.length < 1">Toevoegen</b-button>
        <div class="divider my-2">
          <div class="divider-text">of</div>
        </div>

        <h4 class="mb-1">Voeg een nieuw contact toe</h4>
      </div>
      <!-- Media -->
      <b-media class="mb-2">
        <h4 class="mb-1">
          {{ userData.displayName }}
        </h4>
      </b-media>
      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="updateUser">
        <b-row>
          <!-- Field: Firstname -->
          <b-col cols="12" md="4">
            <b-form-group label="Voornaam" label-for="firstname">
              <b-form-input
                id="firstname"
                :class="$v.userData.firstname.$error ? 'is-invalid' : ''"
                v-model="userData.firstname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Naam" label-for="name">
              <b-form-input
                id="name"
                :class="$v.userData.lastname.$error ? 'is-invalid' : ''"
                v-model="userData.lastname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: DisplayName -->
          <b-col cols="12" md="4">
            <b-form-group label="Weergavenaam" label-for="displayName">
              <b-form-input
                id="displayName"
                :class="$v.userData.displayName.$error ? 'is-invalid' : ''"
                v-model="userData.displayName"
                type="text"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Type -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Type" label-for="type" label-class="mb-1">
              <v-select
                v-model="userData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contactRoles"
                :class="$v.userData.type.$error ? 'is-invalid' : ''"
                :getOptionLabel="(option) => getRoleSingular(option)"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <h6 class="section-label mb-1 mt-2">
              Persoonlijke Contactgegevens
            </h6>
          </b-col>

          <!-- phone -->
          <b-col md="8" class="mb-2">
            <b-row :key="k" v-for="(p, k) in userData.phones">
              <b-col cols="10">
                <b-form-group
                  label-for="phone"
                  :label="'Telefoonnummer ' + (k + 1)"
                >
                  <cleave
                    class="form-control"
                    :options="cleavePhone"
                    :disabled="loading"
                    v-model="userData.phones[k]"
                    :class="
                      $v.userData.phones.$each[k].$error ? 'is-invalid' : ''
                    "
                    placeholder="Telefoonnummer"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removePhone(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addPhone"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Telefoonnummer toevoegen</span>
            </b-button>
          </b-col>
          <!-- phone -->

          <!-- email -->
          <b-col md="8" class="mb-2">
            <b-row :key="k" v-for="(p, k) in userData.emails">
              <b-col cols="10">
                <b-form-group label-for="email" :label="'E-mail ' + (k + 1)">
                  <b-form-input
                    class="form-control"
                    v-model="userData.emails[k]"
                    :name="'email-' + k"
                    :class="
                      $v.userData.emails.$each[k].$error ? 'is-invalid' : ''
                    "
                    :disabled="loading"
                    placeholder="E-mail"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeEmail(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addEmail"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>E-mail toevoegen</span>
            </b-button>
          </b-col>
          <!-- email -->

          <!-- email -->
          <b-col md="8" class="mb-2">
            <b-row :key="k" v-for="(p, k) in userData.addresses">
              <b-col sm="12">
                <h6 class="form-label mb-1 mt-2">
                  {{ k == 0 ? "Hoofdadres" : "Adres " + (k + 1) }}
                </h6>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Straat">
                  <b-form-input
                    class="form-control"
                    v-model="userData.addresses[k].street"
                    :class="
                      $v.userData.addresses.$each[k].street.$error
                        ? 'is-invalid'
                        : ''
                    "
                    :disabled="loading"
                    placeholder="Straat"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Nr.">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].housenumber"
                    :class="
                      $v.userData.addresses.$each[k].housenumber.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Nr."
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Bus">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].addition"
                    :class="
                      $v.userData.addresses.$each[k].addition.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Bus"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeAddress(k)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Postcode">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].postal"
                    :class="
                      $v.userData.addresses.$each[k].postal.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Postcode"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Plaats">
                  <b-form-input
                    class="form-control"
                    :disabled="loading"
                    v-model="userData.addresses[k].city"
                    :class="
                      $v.userData.addresses.$each[k].city.$error
                        ? 'is-invalid'
                        : ''
                    "
                    placeholder="Plaats"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-success"
              size="sm"
              @click="addAddress"
            >
              <feather-icon class="mr-1" icon="PlusIcon" />
              <span>Adres toevoegen</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :disabled="loading"
        >
          Opslaan
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          @click="resetData"
          :disabled="loading"
        >
          Annuleren
        </b-button>
      </b-form>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BFormRadio,
  BOverlay,
  BFormFile,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.be";

import Ripple from "vue-ripple-directive";

import mainMixin from "@/mixins/mainMixin";

export default {
  mixins: [mainMixin],
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
    Cleave,
    BOverlay,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isNew: {
      required: false,
      default: false,
    },
  },
  created(){
    if(this.$store.state.userManagement.contacts.length < 1){
      this.$store.dispatch('userManagement/fetchAllContacts', this.$store.state.organisation.id);
    }
  },
  data() {
    return {
      loading: false,
      searchQuery: "",
      newContacts: [],
      cleavePhone: {
        phone: true,
        phoneRegionCode: "BE",
      },
    };
  },
  computed: {
    filteredContacts() {
      return this.searchArray(
        this.$store.state.userManagement.contacts,
        this.searchQuery
      );
    },
  },
  methods: {
    resetData() {
      this.$v.$reset();
      this.$emit("resetData", null);
    },
    updateUser() {
      if (!this.$v.$invalid) {
        this.loading = true;
        if (!this.userData.emails) this.userData.emails = [];
        if (!this.userData.phones) this.userData.phones = [];
        if (!this.userData.addresses) this.userData.addresses = [];
        this.userData.email = this.userData.emails[0] ?? null;
        this.userData.phone = this.userData.phones[0] ?? null;
        this.userData.address = this.userData.addresses[0] ?? null;
        if (this.isNew) {
          this.$store
            .dispatch("userManagement/newContact", {
              orgId: this.$store.state.organisation.id,
              contact: this.userData,
            })
            .then(() => {
              this.$emit("input", this.userData);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Contact opgeslaan!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          this.$store
            .dispatch("userManagement/updateContact", {
              orgId: this.$store.state.organisation.id,
              contact: this.userData,
            })
            .then(() => {
              this.$emit("input", this.userData);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Contact opgeslaan!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        this.$v.$touch();
      }
    },
    addPhone() {
      if (!this.userData.phones)
        this.userData.phones = this.userData.phone ? [this.userData.phone] : [];
      this.userData.phones.push("");
    },
    removePhone(index) {
      this.userData.phones.splice(index, 1);
    },
    addEmail() {
      if (!this.userData.emails)
        this.userData.emails = this.userData.email ? [this.userData.email] : [];
      this.userData.emails.push("");
    },
    removeEmail(index) {
      this.userData.emails.splice(index, 1);
    },
    addAddress() {
      if (!this.userData.addresses)
        this.userData.addresses = this.userData.address
          ? [this.userData.address]
          : [];
      this.userData.addresses.push({});
    },
    removeAddress(index) {
      this.userData.addresses.splice(index, 1);
    },
    addNewContacts(){
      this.newContacts.forEach((c) => {
        if((c.children && c.children.findIndex(i => i == this.userData.children[0]) == -1) || !c.children){
          if(!c.children)c.children = this.userData.children;
          else c.children.push(this.userData.children[0]);
          
          this.$store
            .dispatch("userManagement/updateContact", {
              orgId: this.$store.state.organisation.id,
              contact: {id: c.id, children: c.children},
            })
            .then(() => {
              this.$emit("input", this.userData);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Contact opgeslaan!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              console.error(err);
            });
        }
        
        
      })
    }
  },
  validations: {
    userData: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      displayName: {
        required,
      },
      type: {
        required,
      },
      emails: {
        $each: {
          required,
          email,
        },
      },
      phones: {
        $each: {
          minLength: minLength(9),
        },
      },
      addresses: {
        $each: {
          street: {
            minLength: minLength(2),
          },
          housenumber: {
            numeric,
          },
          addition: {},
          postal: {
            numeric,
          },
          city: {},
        },
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
