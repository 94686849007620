<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <b-overlay :show="loading">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error
      </h4>
      <div class="alert-body">
       Geen gebruiker gevonden met dit id, ga terug naar het
        <b-link
          class="alert-link"
          :to="{ name: 'user-list'}"
        >
          overzicht
        </b-link>
       voor andere gebruikers.
      </div>
    </b-alert>
    <!-- Media -->
    <div class="d-flex justify-content-between" v-if="userData">
      <b-media class="mb-2"  >
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="profilePic"
            :text="avatarText(userData.displayName)"
            :variant="`light-${resolveUserRoleVariant(userData.type)}`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{userData.displayName}}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button variant="primary" class="mr-1" size="sm" @click="$refs.refInputEl.$el.click()">
            <b-form-file
              ref="refInputEl"
              accept=".jpg, .png"
              :hidden="true"
              plain
              v-model="profileFile"
              @input="inputImageRenderer"
            />
            <span>Profielfoto aanpassen</span>
          </b-button>
        </div>
      </b-media>
      <div>
        <user-dropdown :user="userData" />
      </div>
    </div>

    <b-tabs
      v-if="userData"
      
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Persoonlijke gegevens</span>
        </template>
        <user-edit-tab-account
        @resetData="resetData"
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Contacten</span>
        </template>
        <user-edit-tab-information :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Link Codes -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Koppelcodes</span>
        </template>
        <user-edit-tab-link-codes :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
    </b-overlay>
  </component>
</template>

<script>

import {
  BTab, BTabs, BCard, BAlert, BLink, BFormFile, BMedia, BMediaAside, BMediaBody, BAvatar, BButton, BOverlay
} from 'bootstrap-vue'
import UserEditTabAccount from './UserEditTabAccount.vue'
import mainMixin from '@/mixins/mainMixin';
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabLinkCodes from './UserEditTabLinkCodes.vue'
import UserDropdown from '../user-dropdown/UserDropdown';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";


export default {
  mixins: [mainMixin],

  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink, BFormFile, BMedia, BMediaAside, BMediaBody, BAvatar, BButton, BOverlay,

    UserEditTabAccount,
    UserEditTabInformation,
    UserDropdown,
    UserEditTabLinkCodes
  },

  data(){
    return {
      userData: null,
      loading: false,
      profilePic: null,
      profileFile: null,
    }
  },

  watch: {
    '$route.params.uoid': function (id) {
      this.resetData();
    }
  },

  created()
  {
    if (this.$store.state.organisation) {
      this.loadUser();
    }

    this.unwatch = this.$store.watch(
      (state) => state.organisation,
      (oldValue, newValue) => {
        if (this.$store.state.organisation) {
          this.loadUser();
        }
      }
    );

  },

  methods: {
    resetData(){
      this.loadUser();
    },
    loadUser() {
      const uid = this.$route.params.uoid
    this.$store.dispatch('userManagement/fetchUser', {'uid': uid, orgId: this.$store.state.organisation.id})
      .then(res => { 
        this.userData = res;
        if(!this.userData.custom){
          this.userData.custom = {}
        }
        this.$store.commit('SET_PAGE_TITLE', this.userData.displayName);
        this.profilePic = this.userData.profilePic ? this.userData.profilePic.medium : null;
        
        })
      .catch(err => {
        this.$router.push({name: 'users-list'});
        
        console.error(err) 
        return
      })
    
    },
    avatarText: avatarText,
    inputImageRenderer() {
      const file = this.profileFile;
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.profilePic = reader.result;
          this.uploadFile();
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    uploadFile(){
      this.loading = true;
      this.$store.dispatch('uploadImageToPath', {
        imageName: `/organisations/${this.$store.state.organisation.id}/users/${this.userData.uid || this.userData.uoid}/${this.userData.uid || this.userData.uoid}.${this.profileFile.name.split('.').pop()}`,
        file: this.profileFile,
        notify: (arg) => this.$toast(arg)
      }).then((result) => {
        this.userData.profilePic = result;
        if (!this.userData.uoid) {
          this.userData.uoid = this.$route.params.uoid;
        }
         return this.$store
          .dispatch("userManagement/updateUser", {
            orgId: this.$store.state.organisation.id,
            user: this.userData,
          })     
      }).then(() => {
        this.loading = false;
        this.$toast({
              component: ToastificationContent,
              props: {
                title: "Profiel foto aangepast!",
                icon: "CheckICon",
                variant: "success",
              },
            });
      }).catch(error => {
        this.loading = false;
        console.log(error.message)
         this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error!",
                text: error.message,
                icon: "AlertCirleIcon",
                variant: "danger",
              },
            });
      })
    },
  }
  
}
</script>

<style>

</style>
