<template>
  <div>
    <!-- Header: Personal Info -->

    <b-row>
      <b-col class="mb-1" cols="12">
        <b-button
          class="float-right"
          @click="newContact"
          variant="primary"
          size="sm"
          >Nieuw contact</b-button
        >
      </b-col>
      <b-col :key="c.id" v-for="c in contacts" class="mb-2" lg="12" xl="4">
        <b-card class="w-100">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center wrap">
              <h4 class="mr-50 text-break">
                {{ c.displayName || `${c.firstname} ${c.lastname}` }}
              </h4>
              <div class="mr-25">
                <b-badge
                  pill
                  :variant="`light-${resolveContactRoleVariant(c.type)}`"
                  class="text-capitalize mb-25"
                >
                  {{ getRoleSingular(c.type) }}
                </b-badge>
              </div>
              <div>
              <b-badge
            pill
            v-b-tooltip.hover.top="
              c.uid
                ? 'Er is een Tapp-gebruiker gekoppeld ✅'
                : 'Er is nog geen Tapp-gebruiker gekoppeld 😢'
            "
            :variant="`light-${resolveUserStatusVariant(
              c.uid ? 'active' : 'inactive'
            )}`"
            class="text-capitalize mb-25"
          >
            {{ c.uid ? "actief" : "inactief" }}
          </b-badge>
          </div>
            </div>
            <div class="d-flex align-items-center wrap">
              <b-dropdown
                variant="link"
                size="sm"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item  @click="startEditing(c)">
                  <feather-icon icon="Edit2Icon" />
                  <span class="align-middle ml-50">{{c.displayName}} bewerken</span>
                </b-dropdown-item>
                 <b-dropdown-item v-if="c.email && !c.uid" @click="sendInvite(c)">
                  <feather-icon class="mr-1" icon="SendIcon" />
                  {{ c.firstname || c.displayName }} uitnodigen om Tapp te
                  gebruiken</b-dropdown-item
                >
                <b-dropdown-item variant="warning" @click="unlink(c)">
                  <feather-icon icon="LinkIcon" />
                  <span class="align-middle ml-50">Loskoppelen van {{userData.firstname}}</span>
                </b-dropdown-item>

                <b-dropdown-item variant="danger" @click="removeContact(c)">
                  <feather-icon icon="Trash2Icon" />
                  <span class="align-middle ml-50">Contact verwijderen</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <b-row>
            <b-col class="mb-2" sm="12" md="6" xl="12">
              <h5>E-mail</h5>
              <b-list-group>
                <b-list-group-item
                  :key="'email-' + i"
                  v-for="(e, i) in c.emails"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>
                    <feather-icon class="mr-1" icon="MailIcon" size="16" />{{
                      e
                    }}</span
                  >
                  <b-button @click="startEditing(c)" size="sm" variant="primary"
                    ><feather-icon icon="EditIcon" size="14"
                  /></b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col class="mb-2" sm="12" md="6" xl="12">
              <h5>Telefoon</h5>
              <b-list-group>
                <b-list-group-item
                  :key="'phone-' + i"
                  v-for="(p, i) in c.phones"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>
                    <feather-icon class="mr-1" icon="PhoneIcon" size="16" />{{
                      p
                    }}</span
                  >

                  <b-button @click="startEditing(c)" size="sm" variant="primary"
                    ><feather-icon icon="EditIcon" size="14"
                  /></b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col class="mb-2" sm="12" md="6" xl="12">
              <h5>Adres</h5>
              <b-list-group>
                <b-list-group-item
                  :key="'phone-' + i"
                  v-for="(a, i) in c.addresses"
                  class="d-flex justify-content-between align-items-start"
                >
                  <div class="d-flex">
                    <div class="mr-1">
                      <feather-icon icon="MapPinIcon" size="16" />
                    </div>
                    <div>
                      <p>{{ a.street }} {{ a.housenumber }} {{ a.addition }}</p>
                      <p>{{ a.postal }} {{ a.city }}</p>
                    </div>
                  </div>
                  <b-button @click="startEditing(c)" size="sm" variant="primary"
                    ><feather-icon icon="EditIcon" size="14"
                  /></b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <div class="mr-50">
                <b-avatar-group v-if="Object.keys(children).length > 0">
                  <b-avatar
                    :key="ch"
                    v-for="ch in c.children"
                    :to="{ name: 'users-edit', params: { uoid: ch } }"
                    v-b-tooltip.hover
                    class="pull-up"
                    :title="children[ch] ? children[ch].displayName : ''"
                    :text="avatarText(children[ch] ? children[ch].displayName : '')"
                    :src="
                      children[ch] && children[ch].profilePic
                        ? children[ch].profilePic.small
                        : null
                    "
                  />
                </b-avatar-group>
              </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="modal-event"
      size="lg"
      v-model="contactEditDialogVisible"
      id="modal-event"
      :title="isNew ? 'Contact toevoegen' : 'Contact bewerken'"
    >
      <b-overlay :show="loading">
        <contact-edit-tab-account
          :is-new="isNew"
          @input="finishEditing"
          :user-data="editingC"
        />
      </b-overlay>
      <template #modal-footer><span></span></template>
    </b-modal>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BBadge,
  BModal,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BAvatar,
  BAvatarGroup,
  BDropdown,
  BDropdownItem,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import mainMixin from "@/mixins/mainMixin";
import userVerification from "@/mixins/userVerification";

import { avatarText } from "@core/utils/filter";

import ContactEditTabAccount from "@/views/contacts/contacts-edit/ContactEditTabAccount.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [mainMixin, userVerification],
  components: {
    ContactEditTabAccount,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BModal,
    BOverlay,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BAvatarGroup,
    
  },
  directives: {
    "b-tooltip": VBTooltip,
    'b-modal': VBModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
   watch: {
    $props: {
      handler() {
        this.fetchContacts();
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      contacts: [],
      contactEditDialogVisible: false,
      editingC: null,
      loading: false,
      children: {},
      isNew: false,
      baseContact: {
        emails: [],
        email: null,
        phones: [],
        phone: null,
        addresses: [],
        address: null,
        displayName: null,
        firstname: null,
        lastname: null,
        type: "parent",
      },
    };
  },
  created() {
    this.fetchContacts();
  },
  methods: {
    avatarText,
    sendInvite(contactId) {
      this.loading = true;
      this.inviteContact(
        contactId
      ).then(() => (this.loading = false));
    },
    fetchContacts() {
      if (this.$store.state.userManagement.contacts.length > 0) {
        this.contacts = this.$store.state.userManagement.contacts.filter(
          (item) =>
            (item.children
              ? item.children.findIndex((i) => i == this.userData.uoid)
              : -1) > -1
        );
        this.matchChildren();
      } else {
        this.$store
          .dispatch("userManagement/fetchContacts", {
            orgId: this.$store.state.organisation.id,
            uoid: this.userData.uoid,
          })
          .then((res) => {
            this.contacts = res;
            this.matchChildren();
          });
      }
    },
    startEditing(c) {
      this.editingC = c;
      this.isNew = false;
      this.contactEditDialogVisible = true;
      this.loading = false;
    },
    finishEditing(d) {
      this.contactEditDialogVisible = false;
      this.editingC = {};
      this.isNew = false;
      this.loading = false;
      this.fetchContacts();
    },
    newContact() {
      this.isNew = true;
      this.editingC = {
        ...this.baseContact,
        ...{ children: [this.userData.uoid] },
      };
      this.contactEditDialogVisible = true;
      this.loading = false;
    },
    unlink(c){
      var i = c.children.findIndex(item => this.userData.uoid == item);
      if(i > -1){
        this.loading = true;
        c.children.splice(i, 1);
        this.$store.dispatch('userManagement/updateContact',  {orgId: this.$store.state.organisation.id,
            contact: {id: c.id, children: c.children}}).then(() => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Contact losgekoppeld!",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.fetchContacts();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    removeContact(c){
      this.$bvModal
        .msgBoxConfirm(`Je staat op het punt ${c.displayName} te verwijderen. Dit kan niet ongedaan gemaakt worden.`, {
          title: 'Ben je zeker?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Verwijderen',
          cancelTitle: 'Annuleren',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if(value){
            this.$store
            .dispatch("userManagement/removeContact", {
              orgId: this.$store.state.organisation.id,
              contact: c
            })
            .then(() => {
              this.fetchContacts();
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Contact verwijderd!",
                icon: "CheckIcon",
                variant: "danger",
              },
            });
            });
          }
        })
    },
    matchChildren() {
      this.fetchUsers();
      this.contacts.forEach((c) => {
        c.children.forEach((ch) => {
          var i = this.$store.state.userManagement.users.findIndex(
            (e) => e.uoid == ch
          );
          if (i > 0) {
            this.children[ch] = this.$store.state.userManagement.users[i];
          } 
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
